.buttonContainer
  display: flex
  align-items: center
  width: 100%
  // Alignment
  &.left
    justify-content: flex-start
  &.right
    justify-content: flex-end
    align-items: flex-end
  &.center
    justify-content: center
  &.stretch
    justify-content: center
    width: 100%
  &.rightBottom // Used in cards
    position: absolute
    right: 0
    bottom: 0
    justify-content: flex-end
    padding: 1rem !important
  &.vertical
    flex-direction: column
    gap: 1rem
  &.horizontal
    flex-direction: row
    flex-wrap: nowrap
    gap: 1rem

  span // TODO: Move to Button component?
    font-style: italic
    
// TODO: Move to respective components?
.module
  .buttonContainer
    padding: 0 2rem

.eventCard
  .buttonContainer
    padding: 1rem
