.module-Text_Trait
  display: flex
  width: 100%
  justify-content: space-between
  flex-direction: column
  // opacity: 0  
  // transform: translateY(2vh)
  strong
    width: 100%
    display: block
  span
    display: block
  &.horizontal
    flex-direction: row
    strong
      width: auto
      display: inline
    span
      display: inline
      flex-shrink: 0

    & .line
      border-bottom: 1px dashed hsla(0, 0%, 0%, 0.165)
      flex-grow: 1