.loader
  position: relative
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  padding: 1rem
  .dots
    display: flex
    justify-content: center
    align-items: center
  .dot
    width: 12px
    height: 12px
    border-radius: 50%
    background-color: black
    margin: 0 6px
    animation: dot-elastic-middle 1s infinite linear
    &:first-child
      animation: dot-elastic-first 1s infinite linear
    &:last-child
      animation: dot-elastic-last 1s infinite linear
      
@keyframes dot-elastic-first 
  0% 
    transform: scale(1)
  25% 
    transform: scale(1)
  50% 
    transform: scale(1)
  62.5% 
    transform: scale(1.25)
  75% 
    transform: scale(1)
  87.5%
    transform: scale(1)
  100% 
    transform: scale(1)

@keyframes dot-elastic-middle
  0% 
    transform: scale(1)
  25% 
    transform: scale(1)
  50% 
    transform: scale(1)
  62.5% 
    transform: scale(1)
  75% 
    transform: scale(1.25)
  87.5%
    transform: scale(1)
  100% 
    transform: scale(1)

@keyframes dot-elastic-last 
  0% 
    transform: scale(1)
  25% 
    transform: scale(1)
  50% 
    transform: scale(1)
  62.5% 
    transform: scale(1)
  75% 
    transform: scale(1)
  87.5%
    transform: scale(1.25)
  100% 
    transform: scale(1)