// Container Styling
html,
#root,
.ando,
.App,
body 
  position: relative
  height: 100%
  

html, body
  background: var(--theme-bg)

.App
  width: 100%
  height: 100svh
  overflow-y: visible
  & > div
    margin: 0 auto

// General Styling
img 
  max-width: 100%
  padding: 0
  margin: 0
  display: block

.icon
  position: relative
  width: 2rem 
  height: 2rem
  cursor: pointer
  &-Small
    width: 1.625rem
    height: 1.625rem
  svg
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%

.section
  // opacity: 0
  // transform: translateY(1rem)
  &.forceOpacity
    opacity: 1 !important
    transform: translateY(0) !important

.animateModule
  opacity: 0

.animateToken
  opacity: 0

.animateEventCard
  opacity: 0

//// Colors
.green
  background-color: var(--color-green)
.blue
  background-color: var(--color-blue)
.red
  background-color: var(--color-red)
.ando
  background-color: var(--color-ando)
.mmerch-white
  background-color: var(--color-mmerch-white)
.black
  background-color: var(--color-black)

//// General sections
.App
  padding-top: env(safe-area-inset-top)
  padding-right: env(safe-area-inset-right)
  padding-bottom: env(safe-area-inset-bottom)
  padding-left: env(safe-area-inset-left)

.page 
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start
  gap: var(--spacing-page)
  flex: 1
  min-height: calc(100lvh + 1px) // Keeps address footer collapsed when paired with the scroll to 1px
  padding-top: var(--app-padding-top)
  padding-left: var(--app-padding-sides)
  padding-right: var(--app-padding-sides)
  padding-bottom: var(--app-padding-bottom)
  max-width: var(--width-max-content)
  &-Home
    gap: 4rem
    padding-bottom: 1rem // Prevents different padding on the bottom of the page
  &-Bottom
    margin-top: env(safe-area-inset-bottom)
    height: 1rem
    border: 1px solid transparent
  & > div
    flex-shrink: 0
  .sectionGroup
    display: flex
    flex-direction: column
    gap: 1.5rem
    width: 100%



.group
  &-VertSpacing
    &_One
      display: flex
      flex-direction: column
      gap: 1rem

.icon 
  cursor: pointer
  -webkit-appearance: none
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)!important
  -webkit-touch-callout: none!important
  -webkit-user-select: none!important
  svg.forward
    transform: rotate(180deg)
  svg.link
    transform: rotate(135deg) !important
    width: 1rem
    height: 1rem

.QRCode
  height: 16rem
  width: 16rem
  &_Text
    text-align: center

.truncate
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.loadable
  transition: opacity 1s ease 0.1s

.isLoading
  opacity: 0

.isLoaded
  opacity: 1


//// Specific sections


//// Animation
.animationPulse
  animation: animationPulse 2s linear infinite

@keyframes animationPulse
  0%
    opacity: 1 
  50%
    opacity: 0.1
  100%
    opacity: 1
    
@keyframes textscroll 
  from 
    transform: translateX(0)
  to 
    transform: translateX(-95%)

// Animation
@keyframes spinhoodie   
  0% 
    transform: rotateY(0deg)
  // 50% 
  //   transform: rotateY(360deg)
  100% 
    transform: rotateY(360deg)