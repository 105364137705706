.tokenCard
  color: var(--color-inactive)
  width: calc(50% - .5rem)
  border: var(--color-inactive) solid var(--border-width-inner)
  border-radius: 1.5rem
  overflow: hidden
  text-align: center
  aspect-ratio: 1 / 1
  flex-shrink: 0
  display: flex
  align-items: center
  opacity: 0
  span
    margin: auto
    font-size: var(--type-s)
    line-height: var(--type-line-normal)
    font-weight: var(--type-medium)
    color: currentColor
  img
    border-radius: 0.7rem

.active
  border: var(--border-width-inner) solid var(--theme-border)
  // opacity: 1
  // background: white
  font-size: 0px
  video
    border-radius: .75rem
    width: 100%
    height: 100%
    object-fit: cover
  img
    width: 100%
    height: 100%
    object-fit: 102%