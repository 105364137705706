.module-Text_BulletPoint
  position: relative
  display: flex
  flex-direction: row
  align-items: flex-start
  flex-wrap: nowrap
  gap: 1rem
  span
    position: absolute
    left: 0
    top: .83rem
    transform: translateY(-50%)
    background: black
    width: 0.25rem
    height: 0.25rem
    border-radius: 100%
  .number
    display: inline-block
    padding: .5rem
    border-radius: 100px
    border: 2px solid var(--theme-border)
    border: var(--border-width-inner) solid var(--theme-border)
    p
      font-weight: var(--type-bold)
  .content
    min-height: 100%
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: safe center