.module
  position: relative
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  gap: 2rem
  padding-bottom: 3rem
  border: var(--theme-border) solid var(--border-width-container)
  border-radius: var(--border-radius-container)
  background: var(--theme-module)
  // 3D effect
  transform-style: "preserve-3d"
  transform-perspective: 1000

  & > div:first-child
    &.module
      &-Image
        padding-top: 1rem
        &.noTopPadding
          padding-top: 0
      &-Text
        padding-top: 4rem
      &-Slider
        padding-top: 1rem

  &-Title
    width: 100%
    h1
      // Copied from typography.sass => .typeDisplay
      font-size: var(--type-xl)
      line-height: var(--type-line-normal)
      font-weight: var(--type-bold)
      color: currentColor
    // h2
    //   font-size: var(--type-l)
    //   line-height: var(--type-line-titles)
    //   font-weight: var(--type-medium)
    //   color: currentColor
    p
      font-size: var(--type-s)
      line-height: var(--type-line-normal)
      font-weight: var(--type-normal)
      color: currentColor 
    &.center
      text-align: center

  &-Text
    width: 100%
    display: flex
    flex-direction: column
    gap: 2rem
    padding: 0 2rem
    i
      font-style: italic
    &_Link
      & > a 
        position: relative
        text-decoration: none 
        & > .icon
          position: absolute
          right: 0
          transform: translateX(107%)
          top: 7%
          svg
            width: 0.75rem
            height: .75rem
    &_List
      display: flex
      flex-direction: column
      gap: 1rem
    &.center
      align-items: center

  &-Image
    width: 100%
    border: var(--border-width-container) solid var(--theme-border)
    border-radius: var(--border-radius-container)
    overflow: hidden
    font-size: 0
    box-shadow: 0 1px 
    & img
      width: 100%
      height: 100%
      object-fit: cover
    &.unPadded
      border: 0
    &.noBorder
      border: 0
      box-shadow: none
    &.noTopPadding
      padding-top: 0
    &.isSquare
      aspect-ratio: 1/1

  &-Tokens
    display: flex
    flex-wrap: wrap
    gap: 1rem

  & > .module-Image
    border: 0
    outline: var(--theme-border) solid var(--border-width-container)
    
  &.card
    & > div:first-child 
      padding-top: 3rem

  &.hasEvenCorners
    border-radius: var(--border-radius-container-even)
    .module-Image
      border-radius: var(--border-radius-container-inner)
  &.noTopPadding
    padding-top: 0 
    .module-Image
      border-radius: var(--border-radius-container-inner)
  &.noBottomPadding
    padding-bottom: 0
    .module-Image
      border-radius: var(--border-radius-container-inner)

.settingsList
  display: flex
  flex-direction: column
  gap: 2rem
  &-Item
    display: flex
    flex-direction: column
    gap: .25rem
    &_Top
      position: relative
      display: flex
      flex-direction: row
      flex-wrap: nowrap
      justify-content: space-between
      .icon
        position: absolute
        top: 0
        right: 0
    &_Bottom
      display: flex
      flex-direction: column
    &_Title, &_Item
      padding-right: 2rem
    