.eventCard
  position: relative
  width: 100%
  border: var(--theme-border) solid var(--border-width-container) 
  border-radius: var(--border-radius-container-even)
  aspect-ratio: 1 / 1.23
  object-fit: cover

.buttonContainer
  position: absolute
  bottom: 0
  left: 0
  right: 0
  padding: 1rem
  z-index: 1
