.traitsModule
  position: relative
  width: 100%
  &-Content
    display: flex
    flex-direction: column
    align-items: center
    gap: 2rem
    border-radius: var(--border-radius-container-even)
    padding-bottom: 3rem
    background: var(--theme-module)
    border: var(--theme-border) solid var(--border-width-container)
  &-Traits
    display: flex
    flex-direction: column
    gap: 0.15rem
    flex-shrink: 1
    flex-grow: 1
    width: 100%
    padding: 0 2rem
    overflow: hidden 
  &-TitleContainer
    display: flex
    flex-direction: column
    align-items: center
    gap: 1rem
    flex-shrink: 0
  &-DesignTraits
    display: flex
    align-items: center
    gap: 1rem
    