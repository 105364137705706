.landing
  position: relative
  width: 100%
  height: 14rem
  perspective: 1000px
  &_Image
    z-index: -1
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    padding: 0 4rem
    img
      width: 100%
      height: 100%
      object-fit: contain
      scale: 1.19
  &_Text
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    width: 100%
    text-align: center
    h1
      font-size: 4rem
      line-height: var(--type-line-normal)
      font-weight: var(--type-bold)
      color: white
      letter-spacing: -0.025em