.modal
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  pointer-events: none
  z-index: 999
  & .buttonContainer
    padding: 0 2rem
  & .buttonContainer, & .button
    opacity: 1
    transform: translateY(0)
  &-Background
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: hsla(0, 0%, 0%, .66)
    opacity: 0
    transition: opacity 1s ease
  &-Container
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    z-index: +1
    color: var(--theme-type)
    background: var(--theme-module)
    border-top: var(--border-width-btn) solid var(--theme-type)
    border-bottom: 1px solid var(--color-inactive)
    transition: transform .33s ease
    transform: translateY(100%)
  &-Content
    display: flex
    flex-direction: column
    align-items: center
    gap: 2rem
    width: 100%
    max-width: var(--width-max-content)
    border: 0
    padding: 0 1rem
    padding-bottom: 3rem
    z-index: +1
    & > p, & > span, & > a
      text-align: center
      font-size: var(--type-m)
      line-height: var(--type-line-normal)
      font-weight: var(--type-regular)
      color: currentColor
    & > .button
      width: 100%
  &-CloseBar
    display: flex
    justify-content: center 
    padding: 8px
    width: 100%
    &Item
      height: var(--border-width-btn)
      width: 38px
      background: currentColor

  &-Title
    text-align: center
  
.modal[data-state="open"]
  .modal-Background
    opacity: 1
    pointer-events: auto
  .modal-Container
    pointer-events: auto
    transform: translateY(0%)
