.nav
  position: fixed
  top: 1rem
  left: 50%
  width: 100%
  max-width: calc(#{var(--width-max-content)} - #{var(--app-padding-sides)} - #{var(--app-padding-sides)})
  height: 3.875rem
  transform: translateX(-50%)
  color: var(--theme-type)
  background: var(--theme-module)
  border: var(--border-width-btn) solid var(--theme-border)
  border-radius: var(--border-radius-btn)
  overflow: hidden
  z-index: +1
  transition: width 0.25s ease, height 0.25s ease, border-radius 0.25s ease, top 0.25s ease 
  &-Toggler
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 0
    pointer-events: auto
  &-Collapsed
    display: flex
    flex-wrap: nowrap
    align-items: center
    justify-content: space-around
    padding: 0
    width: 100%
    height:  100%
    opacity: 1
    transition: opacity 0.25s ease
    & > a
      width: 100%
      height: 100%
      display: flex
      justify-content: center
      align-items: center
    .indicator
      will-change: left, width, height, border-radius, opacity
      position: absolute
      display: flex
      align-items: center
      left: 12.5%
      top: 50%
      transform: translate(-50%, -50%)
      width: 33%
      height: 100%
      background: var(--theme-button)
      transition: left 0.25s ease, border-radius 0.25s ease, width .25s ease, height .25s ease, opacity .25s ease

      // INCASEOF FOUR ITEMS
      // &[data-state='0']
      //   border-radius: 1rem 1rem 1rem 0.5rem
      //   opacity: 1
      // &[data-state='1']
      //   left: 37.5%
      //   border-radius: 1rem 1rem 1rem 1rem
      //   opacity: 1
      // &[data-state='2']
      //   left: 62.5%
      //   border-radius: 1rem 1rem 1rem 1rem
      //   opacity: 1
      // &[data-state='3']
      //   left: 87.5%
      //   border-radius: 1rem 1rem 0.5rem 1rem
      //   opacity: 1

      //INCASEOF THREE ITEMS
      &[data-state='0']
        left: 16.6666%
        border-radius: 1rem 1rem 1rem 0.75rem
        opacity: 1
      &[data-state='1']
        left: 50%
        border-radius: 1rem
        opacity: 1
      &[data-state='2']
        left: 83.3333%
        border-radius: 1rem 1rem .75rem 1rem
        opacity: 1
      &[data-active='true']
        width: 33%
        height: 100%
        opacity: 1
      &[data-active='false']
        width: 28%
        height: 84%
        opacity: 1
        border-radius: 1rem

      // INCASEOF FOUR ITEMS
      // &[data-state='0']
      //   &[data-active='false']
      //     left: 12.5%
      // &[data-state='1']
      //   &[data-active='false']
      //     left: 37.5%
      // &[data-state='2']
      //   &[data-active='false']
      //     left: 62.5%
      // &[data-state='3']
      //   &[data-active='false']
      //     left: 87.5%

      //INCASEOF THREE ITEMS
      &[data-state='0']
        &[data-active='false']
          left: 16.75%
      &[data-state='1']
        &[data-active='false']
          left: 50%
      &[data-state='2']
        &[data-active='false']
          left: 62.5%
  &-Expanded
    display: none
    flex-direction: column
    align-items: center
    gap: 1rem
    padding-top: var(--app-padding-top)
    padding-bottom: var(--app-padding-bottom)
    padding-left: var(--app-padding-sides)
    padding-right: var(--app-padding-sides)
    .buttonContainer
      z-index: +1
      pointer-events: auto
  // &.active
  //   top: .5rem
  //   height: 8rem
  //   border-radius: 1.5rem
    // .nav-Collapsed
    //   opacity: 0
    // .nav-Expanded
    //   display: flex
  &-Icon
    pointer-events: auto
    z-index: +1
    a
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      -webkit-appearance: none
    a > svg:last-child
      display: none
    a.active > svg:first-child
      display: none
    a.active > svg:last-child
      display: inline-block

