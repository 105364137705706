body 
  margin: 0
  font-family: 'Space Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

html 
  font-size: 16px
  color: var(--theme-type)


// @media screen and (min-width: 800px) 
//   html 
//     font-size: calc(16px + 8 * ((100vw - 800px) / 600))

// @media screen and (min-width: 1400px) 
//   html 
//     font-size: 24px
  
h1,
h2,
h3,
h4,
p,
a,
li,
button,
input,
textarea 
  font-family: 'Space Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif

.typeSmall, .typeSmall p, .typeSmall span, .typeSmall a
  font-size: var(--type-s)
  line-height: var(--type-line-normal)
  font-weight: var(--type-regular)
  color: currentColor

p, .typeNormal, .typeNormal p, .typeNormal span, .typeNormal a
  font-size: var(--type-m)
  line-height: var(--type-line-normal)
  font-weight: var(--type-regular)
  color: currentColor

h1, .typeLarge, .typeLarge p, .typeLarge h1, .typeLarge h2, .typeLarge span, .typeLarge a
  font-size: var(--type-l)
  line-height: var(--type-line-normal)
  font-weight: var(--type-regular)
  color: currentColor

.typeDislay, .typeDislay p, .typeDislay h1, .typeDislay h2, .typeDislay
  font-size: var(--type-xl)
  line-height: var(--type-line-normal)
  font-weight: var(--type-medium)
  color: currentColor

.typeSmall, .typeNormal, .typeLarge, .typeDislay, p, h1, h2, span, a
  &.normal
    font-weight: var(--type-regular)
  &.medium
    font-weight: var(--type-medium)
  &.bold
    font-weight: var(--type-bold)

.subtitle
  font-size: var(--type-s)
  line-height: var(--type-line-subtitle)
  font-weight: var(--type-medium)
  color: currentColor
  text-transform: uppercase
  letter-spacing: 0.125em

.date
  text-transform: uppercase

.button, .button p, .button span
  font-size: var(--type-m)
  font-weight: 500
  letter-spacing: 0.025em
  line-height: 1em
  text-decoration: none
  text-transform: uppercase

p > a,
a,
a:link,
a:active,
a:hover,
a:visited 
  color: currentColor
  -webkit-appearance: none
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)!important
  -webkit-touch-callout: none!important
  -webkit-user-select: none!important

.mono
  font-family: 'Space Mono', monospace
  letter-spacing: -1%

