.module-Slider
  display: flex
  flex-direction: column
  gap: 1.5rem
  color: transparent
  width: 100%
  overscroll-behavior-x: contain // Prevents back navigation on swipes
  &_Image
    position: relative
    aspect-ratio: 1 / 1.38
    width: 100%
    overflow: hidden
    border-radius: 3rem 3rem 0 0
    outline: var(--theme-border) solid var(--border-width-container)
    border-radius: var(--border-radius-container-inner)
    overflow: hidden
    img
      width: 100%
      height: 100%
      object-fit: cover
      transform: scale(1)
    &[data-value="0"]
      img
        object-fit: cover
        position: absolute
        left: 0
        top: 0
        transform: scale(1)
    &[data-value="1"]
      img
        object-fit: cover
        position: absolute
        left: 0
        top: 0
        transform: scale(1.075)

  .nav
    &-Dots
      display: flex
      justify-content: center
      gap: .5rem
      min-height: .5rem // To prevent jumping
      pointer-events: auto
    &-Dot
      position: relative
      border-radius: 1000px
      width: .5rem
      height: .5rem
      cursor: pointer
      transition: background .33s ease, width .33s ease
      background: var(--theme-type)
      &.active
        background: var(--theme-active)
        width: 1rem

  &_Caption
    color: var(--color-black) !important
    display: flex
    align-items: safe center
    justify-content: safe center
    font-family: 'Space Mono', monospace
    gap: .5rem
    img
      width: 1.25rem
      height: 1.25rem
      object-fit: contain
