:root {
  /* Colors */
  --color-type: black;
  --color-mmerch-white: #f5fff5;
  --color-ando: #c7c4b9;
  --color-red: #d66339;
  --color-green: #bdf94e;
  --color-blue: #1421f3;
  --color-black: #000000;
  --color-inactive: hsla(0, 0%, 0%, 0.2);
  --color-emphasized: hsla(0, 0%, 0%, 0.06);
  --theme-bg: var(--color-ando);
  --theme-button: var(--color-green);
  --theme-type: var(--color-black);
  --theme-active: var(--color-red);
  --theme-module: var(--color-mmerch-white);
  --theme-border: var(--color-black);

  /* Spacing */
  --width-max-content: 25rem;
  --width-icon: 2rem;
  --app-padding-sides: 1rem;
  /* 1 + 4.5 + 2 */
  --app-padding-top: 7.5rem;
  --app-padding-bottom: 3rem;
  --spacing-page: 2rem;
  --spacing-modules: 1rem;
  --spacing-content-top: calc(6.85rem + 2rem);

  /* z-index */
  --zindex-nav: 999;

  /* Borders */
  /* 64 64 32 32 */
  --border-radius-container: 4rem 4rem 2rem 2rem;
  --border-radius-container-even: 2rem 2rem 2rem 2rem;
  --border-radius-container-inner: 1.75rem;
  --border-radius-container-top: 4rem 4rem 0 0;
  --border-width-container: 4.5px;
  --border-width-inner: 3.5px;
  /* 48 48 32 32 */
  --border-radius-btn: 2rem 2rem 1rem 1rem;
  --border-width-btn: 4.5px;
  --border-radius-btn-back: 2rem 2rem 1rem 2rem;
  --border-radius-btn-forward: 2rem 2rem 2rem 1rem;
  --border-radius-btn-round: 2rem 2rem 2rem 2rem;

  /* Type */
  /* 14px */
  --type-s: 0.875rem;
  /* 16px */
  --type-m: 1rem;
  /* 20px */
  --type-l: 1.25rem;
  /* 26px */
  --type-xl: 1.625rem;
  /* Weights */
  --type-regular: 400;
  --type-medium: 500;
  --type-bold: 700;
  /* Heights */
  --type-line-titles: 1.25em;
  --type-line-normal: 1.45em;

  /* Breakpoint Spacing */
  --breakpoint-mobile: 768px;
}

/* @media only screen and (max-width: 768px) {
  :root {
    --width-max-content: 100%;
  }
} */
