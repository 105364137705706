.button
  display: flex
  justify-content: center
  align-items: center
  gap: .25rem
  padding: .66rem 2rem
  height: 62px
  border: var(--border-width-btn) solid var(--theme-border)
  border-radius: var(--border-radius-btn)
  background-color: var(--theme-button)
  cursor: pointer
  -webkit-appearance: none
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)!important
  -webkit-touch-callout: none!important
  -webkit-user-select: none!important

  // Button State 
  &.disabled
    background-color: #D9D9D9
    color: #B5B5B5
    border-color: #B5B5B5
    pointer-events: none
  &.editable
    background-color: var(--color-red)
    color: white
  &.completed
    background-color: black
    color: white
  &.action
    position: relative
    padding: 0
    border: 0
    background-color: transparent
    height: unset
    width: unset
    & > .icon
      display: none
    &::after
      position: absolute
      left: 0
      bottom: calc(0px - #{var(--border-width-inner)})
      height: var(--border-width-inner)
      width: 100%
      content: ''
      background-color: black
      
    
  // Button Stretch
  &.horizontal
    width: 100%
  &.vertical
    height: 96px

  // Button Form
  &.round
    border-radius: var(--border-radius-btn-round)
  &.back
    border-radius: var(--border-radius-btn-back)
  &.forward
    border-radius: var(--border-radius-btn-forward)

  // Icons Style
  .icon
    position: relative
    width: var(--width-icon)
    height: var(--width-icon)
  svg
    width: var(--width-icon)
    height: var(--width-icon)

